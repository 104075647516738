<template>
  <div>
    <div v-if="!noThread">
      <v-row align="center" justify="center">
        <v-col cols="4">
          <v-card class="text-center pa-5">
            <v-icon color="primary" x-large>mdi-account-details</v-icon>
            <div class="headline mt-2">
              No messages
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-card :loading="loading">
        <v-toolbar flat v-if="thread" class="mb-8">
          <v-row class="mt-10">
            <v-col cols="auto">
              <v-card-title>{{ thread.ticket_number }}</v-card-title>
            </v-col>
            <v-spacer></v-spacer>
            <!-- <v-col cols="auto">
            <v-card-subtitle class="pb-0">Reply from:</v-card-subtitle>
            v-btn style="text-transform:none;" text @click="newEditAssignee()">
            <v-card-text text>
              <div v-if="thread.assignee.username">
                <p class="mt-2 assignee-gray">
                  {{ thread.assignee.username }}
                   <v-icon>mdi-account-edit</v-icon> 
                </p>
              </div>
               <div v-else>
                <p class="mt-2 assignee-gray">
                  Add
                  <v-icon>mdi-account-plus</v-icon>
                </p>
              </div>
            </v-card-text>
          </v-col> -->
            <v-col cols="2">
              <v-card-subtitle class="pb-0 pl-0">
                Status:
              </v-card-subtitle>
              <div>
                <v-select
                  v-model="messageThreadStatus"
                  :items="messageThreadOptions"
                  menu-props="auto"
                  item-value="value"
                  item-text="text"
                  color="primary"
                  @change="updateTicketStatus(getTaskStatus)"
                  style="z-index: 101"
                  :disabled="!thread.can_manage_ticket"
                ></v-select>
              </div>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-divider></v-divider>

        <div v-if="crmMaintenanceDataExists(thread)">
          <v-hover
            v-slot="{ hover }"
            :style="{
              cursor: hasCrmMaintenanceData(thread) ? 'pointer' : 'default',
              zIndex: hasCrmMaintenanceData(thread) ? 100 : 0,
            }"
          >
            <v-card
              @click="openMaintenance(thread)"
              :disabled="!hasCrmMaintenanceData(thread)"
              flat
              :elevation="hover && hasCrmMaintenanceData(thread) ? 5 : 0"
              :class="{ 'on-hover': hover, 'py-4': true }"
            >
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon class="grey lighten-1" dark>
                    mdi-hammer-wrench
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    Maintenance
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-content>
                  <v-list-item-subtitle>Overdue:</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="!!thread.maintenance_data">{{
                    thread.maintenance_data.overdue_services_count
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-content>
                  <v-list-item-subtitle>Upcoming:</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="!!thread.maintenance_data">{{
                    thread.maintenance_data.upcoming_services_count
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-content></v-list-item-content>

                <v-list-item-action>
                  <v-chip outlined v-if="hasCrmMaintenanceData(thread)">
                    Show Details
                  </v-chip>
                  <v-chip outlined v-else>
                    No permission
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-hover>
        </div>

        <v-divider v-if="crmMaintenanceDataExists(thread)"></v-divider>

        <v-card flat v-if="hasServiceLog(thread.messages)" class="ml-4">
          <v-row no-gutters>
            <v-col cols="2">
              <div v-for="(message, index) in thread.messages" :key="index">
                <div
                  v-for="(attachment, index) in message.attachments"
                  :key="index"
                >
                  <v-card-title
                    v-if="!!attachment.service_log_entry"
                    class="ml-1 assignee-gray"
                    style="text-transform: none"
                  >
                    <v-icon class="mr-1">mdi-book-outline</v-icon>
                    Service log
                  </v-card-title>
                </div>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="3" class="pl-1">
              <v-card-subtitle class="pb-0"> Content:</v-card-subtitle>
              <v-card-text text>
                <v-row
                  v-for="(message, index) in thread.messages"
                  :key="index"
                  no-gutters
                >
                  <v-col
                    cols="12"
                    v-for="(attachment, index) in message.attachments"
                    :key="index"
                  >
                    <p
                      class="mt-2 assignee-gray body-2 text-truncate"
                      v-if="attachment.service_log_entry"
                    >
                      {{
                        getClosedNote(
                          attachment.service_log_entry.resolving_note
                        )
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>

            <v-col cols="5" class="pl-6">
              <v-card-subtitle class="pb-0 pl-0"> Documents:</v-card-subtitle>
              <div v-for="(message, index) in thread.messages" :key="index">
                <v-row
                  v-for="(attachment, index) in message.attachments"
                  :key="index"
                  no-gutters
                  class="mb-1"
                >
                  <v-col cols="12" v-if="attachment.service_log_entry">
                    <div
                      v-for="(document, index) in getClosedNoteAttachments(
                        attachment.service_log_entry.resolving_note
                      )"
                      :key="index"
                    >
                      <v-btn :href="document.file_link" text
                        ><v-icon color="grey darken-2">mdi-file-image</v-icon>
                        <v-list-item-subtitle
                          class="
                            text-none
                            subtitle-2
                            ml-2
                            assignee-gray
                            text-truncate
                          "
                          >{{ document.title }}</v-list-item-subtitle
                        ></v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="1" class="mt-2 mr-5" v-if="!messageThreadStatus">
              <v-row justify="end">
                <v-btn icon @click="editCloseServiceReminderDialog">
                  <v-icon>mdi-pencil</v-icon></v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <v-divider v-if="hasServiceLog(thread.messages)"></v-divider>
        <div v-if="!loading">
          <div v-for="(message, index) in getThreadSortedByDate" :key="index">
            <div v-if="showAllMessages">
              <v-card-text class="pa-0" v-if="thread">
                <div
                  v-if="
                    index === 0 ||
                      index === thread.messages.length - 1 ||
                      index === thread.messages.length - 2
                  "
                >
                  <message
                    :message="message"
                    :key="index"
                    @edit-message="passNoteData"
                  ></message>
                  <div v-if="index === 0">
                    <div
                      class="
                        mt-1
                        mb-1
                        d-flex
                        align-center
                        text-center
                        justify-center
                      "
                    >
                      <v-btn
                        text
                        color="grey darken-1"
                        @click="showAllMessages = false"
                      >
                        <v-hover v-slot:default="{ hover }" open-delay="100">
                          <div v-if="!hover">
                            <p class="mt-4" style="text-transform: none">
                              <v-icon>mdi-eye-outline</v-icon>
                              Show all messages ({{
                                getThreadSortedByDate.length - 3
                              }})
                            </p>
                          </div>
                          <div v-else>
                            <p class="mt-4" style="text-transform: none;">
                              <v-icon color="primary">mdi-eye-outline</v-icon>
                              Show all messages ({{
                                getThreadSortedByDate.length - 3
                              }})
                            </p>
                          </div>
                        </v-hover>
                      </v-btn>
                    </div>
                  </div>
                  <v-divider></v-divider>
                </div>
              </v-card-text>
            </div>
            <div v-else>
              <v-card-text class="pa-0" v-if="thread">
                <message
                  :message="message"
                  :key="message.content"
                  @edit-message="passNoteData"
                ></message>
                <!-- <v-divider v-if="index + 1 < thread.messages.length" :key="index"></v-divider> -->
              </v-card-text>
            </div>
          </div>
        </div>
      </v-card>

      <note-inline-dialog
        v-if="note.active"
        :note="note"
        :thread-uuid="thread.uuid"
        :thread-assignee="thread.assignee"
        :thread-partner="thread.partner"
        @reload-messages="loadMessageThread(thread.uuid)"
        :key="forceUpdate"
      ></note-inline-dialog>

      <v-card tile v-if="!note.active">
        <v-row align="center" justify="end">
          <div class="mr-3">
            <v-btn
              :disabled="!thread.can_manage_ticket"
              text
              @click="openNewTask(thread.uuid, thread.messages)"
              >Create task</v-btn
            >
            <v-btn
              :disabled="!thread.can_manage_ticket || thread.resolved"
              text
              v-if="numberOfMembers !== 0"
              @click="openReplyToCustomer"
              >Reply</v-btn
            >
            <v-btn :disabled="!thread.can_manage_ticket" text @click="openNote"
              >Add note</v-btn
            >
          </div>
        </v-row>
      </v-card>
      <assignee-dialog
        v-if="assigneeDialog.active"
        :dialog="assigneeDialog"
        @assignee="assigneeDialogHandler"
      ></assignee-dialog>
      <close-service-reminder-dialog
        v-if="serviceReminder.active"
        :dialog="serviceReminder"
        :thread-messages="thread.messages"
        @saveDocument="handleCloseServiceReminderDialog"
      ></close-service-reminder-dialog>
    </div>
  </div>
</template>

<script>
import api from "@/utils/api.js";
import Message from "@/components/messages/Message";
import { mapGetters } from "vuex";
import AssigneeDialog from "@/components/dialogs/AssigneeDialog";
import { USER_CRM_REQUEST } from "@/store/actions/user";
import NoteInlineDialog from "@/components/dialogs/NoteInlineDialog.vue";
import CloseServiceReminderDialog from "@/components/messages/CloseServiceReminderDialog";
import snackbar from "@/utils/snackbar";

export default {
  name: "MessageThread",
  components: {
    Message,
    AssigneeDialog,
    NoteInlineDialog,
    CloseServiceReminderDialog,
  },
  props: {
    initialData: {
      type: Object,
      default: null,
    },
    noThread: {
      type: Boolean,
      default: false,
    },
    newTicket: {
      type: Object,
      default: Object,
    },
    triggerUpdate: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    loading: true,
    thread: { assignee: { username: null }, members: [] },
    assigneeDialog: { active: false, uuid: null },
    showAllMessages: false,
    messageThreadStatus: null,
    forceUpdate: 0,
    messageThreadOptions: [],
    statusOptions: [],
    note: {
      editedNote: { content: "", title: "", recipient: "" },
      members: [],
      active: false,
    },
    serviceReminder: {
      active: false,
      note: {},
      isEdit: false,
      boat: {},
    },
  }),

  created() {
    this.setInitialData();
    if (Object.keys(this.initialData).length === 0) {
      this.loadMessageThread(this.$route.params.id);
    } else {
      this.initData(this.initialData);
    }
  },

  computed: {
    ...mapGetters(["getMe"]),
    getTaskStatus() {
      return this.thread.messages.flatMap((element) => {
        return element.attachments.filter((el) => {
          if (el.crm_internal_task) return el.crm_internal_task.status;
        });
      })[0];
    },
    getThreadSortedByDate() {
      const attachments = this.thread.messages
        .flatMap((element) => {
          return element.attachments.filter((el) => {
            if (el.crm_internal_task) return el.crm_internal_task.status;
          });
        })
        .map((attachment) => {
          return {
            ...attachment,
            type: "task",
          };
        });

      const messages = this.thread.messages.map((element) => {
        return { ...element, type: "message", subtype: element.type };
      });

      return [...attachments, ...messages].sort(
        (a, b) => new Date(a.created) - new Date(b.created)
      );
    },
    pageHeight() {
      return document.body.scrollHeight;
    },
    numberOfMembers() {
      return this.thread.members.length;
    },
  },

  methods: {
    setInitialData() {
      this.messageThreadOptions = [
        { text: "Active", value: true },
        { text: "Closed", value: false },
      ];
      this.statusOptions = [
        {
          status: "NOT_STARTED",
          name: "Not started",
        },
        {
          status: "IN_PROGRESS",
          name: "In progress",
        },
        {
          status: "COMPLETED",
          name: "Completed",
        },
      ];
    },
    openMaintenance(thread) {
      if (thread.crm_boat === null || !this.hasCrmMaintenanceData(thread)) {
        snackbar.error("Insufficient rights");
        return;
      }

      this.$router.push({
        name: "services",
        query: {
          crm_boat_uuid: thread.crm_boat.uuid,
          page: 1,
          service_status: "",
        },
      });
    },
    initData(resp) {
      this.thread = resp;
      if (typeof this.$route.query["entry_id"] !== "undefined") {
        this.showAllMessages = false;
      } else {
        this.getThreadSortedByDate.length > 3
          ? (this.showAllMessages = true)
          : (this.showAllMessages = false);
      }
      this.loading = false;
      this.thread.resolved
        ? (this.messageThreadStatus = false)
        : (this.messageThreadStatus = true);
    },
    openNewTask(uuid, messages) {
      api.createTaskFromMessage(uuid, messages[0].uuid).then((resp) => {
        this.$router.push({
          name: "TasksEdit",
          params: { id: resp.data.uuid },
        });
      });
    },

    getTaskHash(thread) {
      if (typeof thread === "undefined") return "";

      return thread.uuid.slice(28, thread.uuid.length);
    },

    hasCrmInternalTask(messages) {
      if (typeof messages === "undefined") return false;
      return messages[0].attachments.some((element) => {
        if (element.crm_internal_task) {
          return !!element.crm_internal_task;
        }
      });
    },

    crmMaintenanceDataExists(thread) {
      if (typeof thread === "undefined") return false;

      return thread.maintenance_data !== null && thread.crm_boat !== null;
    },

    hasCrmMaintenanceData(thread) {
      if (typeof thread === "undefined") return false;

      return (
        typeof thread.maintenance_data !== "undefined" &&
        thread.maintenance_data !== null &&
        thread.maintenance_data.has_maintenance_permisson
      );
    },

    hasServiceLog(messages) {
      if (typeof messages === "undefined") return false;
      return messages[0].attachments.some((element) => {
        if (element.service_log_entry) {
          return !!element.service_log_entry.resolving_note;
        }
      });
    },
    getClosedNote(resolving_note) {
      if (resolving_note) {
        return resolving_note.content;
      }
    },
    getClosedNoteAttachments(resolving_note) {
      if (resolving_note) {
        return resolving_note.attachments;
      }
    },
    getPercentageOfCompletion(task) {
      if (task.all_subtasks === 0 && task.done_subtasks === 0) {
        return 0;
      }
      return Math.round((task.done_subtasks * 100) / task.all_subtasks);
    },

    getStatus(status) {
      return this.statusOptions.find(
        (item) => item.status === status.crm_internal_task.status
      ).name;
    },

    getTaskAssignee(status) {
      return !!status.assignee && status.assignee.length !== 0
        ? status.assignee
        : "No user";
    },

    openTask(uuid) {
      this.$router.push({
        name: "tasksDetails",
        params: { id: uuid },
      });
    },
    passNoteData(note) {
      this.note.editedNote = note;
      this.note.active = true;
      this.forceUpdate++;
    },

    openNote() {
      this.note.editedNote = {
        type: "NOTE",
        postType: null,
        content: "",
        title: "",
        recipient: "",
      };
      this.note.members = this.thread.members;
      this.note.active = true;
      this.$vuetify.goTo(this.pageHeight);
    },

    openReplyToCustomer() {
      this.note.editedNote = {
        type: "EMAIL",
        postType: this.thread.messages[0].type || "EMAIL",
        content: "",
        title: "",
        recipient: this.thread.creator.username,
      };
      this.note.members = this.thread.members;
      this.note.active = true;
      this.$vuetify.goTo(this.pageHeight);
    },

    newEditAssignee() {
      this.assigneeDialog.active = true;
      this.assigneeDialog.uuid = this.thread.uuid;
    },

    assigneeDialogHandler(val) {
      if (val) {
        api.assignUsers(this.$route.params.id, val).then(() => {
          this.loadMessageThread(this.$route.params.id);
        });
      }
      this.assigneeDialog.active = false;
    },

    updateTicketStatus(task) {
      this.messageThreadStatus ? this.reopenTicket() : this.ticketType(task);
    },

    reopenTicket() {
      var getReopenTicket = api.reopenTicket(this.thread.uuid);
      var getMe = this.$store.dispatch(USER_CRM_REQUEST);

      Promise.all([getReopenTicket, getMe]).then((values) => {
        this.thread = values[0].data;
        this.loading = false;
        this.$emit("update", values[0].data);
      });
    },

    ticketType(task) {
      if (
        this.thread.type === "SERVICE_REQUEST" ||
        (typeof task !== "undefined" &&
          typeof task.crm_internal_task !== "undefined" &&
          typeof task.crm_internal_task.uuid !== "undefined") ||
        this.thread.type === "COMMISSIONING_REQUEST"
      ) {
        this.serviceReminder.active = true;
        this.serviceReminder.note = {};
        this.serviceReminder.isEdit = false;
        this.serviceReminder.boat = this.thread.crm_boat;
      } else {
        this.closeTicket();
      }
    },

    editCloseServiceReminderDialog() {
      this.serviceReminder.active = true;
      this.serviceReminder.note = this.thread.messages[0].attachments[0].service_log_entry.resolving_note;
      this.serviceReminder.isEdit = true;
    },

    handleCloseServiceReminderDialog(event) {
      if (!event) {
        if (!this.serviceReminder.isEdit) {
          this.messageThreadStatus = true;
        }
        this.serviceReminder.active = false;
      } else {
        this.closeTicket();
        this.serviceReminder.active = false;
      }
      api.getTicket(this.$route.params.id).then((resp) => {
        this.$emit("update", resp.data);
      });
    },

    closeTicket() {
      var getCloseTicket = api.closeTicket(this.thread.uuid);
      var getMe = this.$store.dispatch(USER_CRM_REQUEST);

      Promise.all([getCloseTicket, getMe]).then((values) => {
        this.thread = values[0].data;
        this.loading = false;
        this.$emit("update", values[0].data);
      });
    },

    loadMessageThread(threadUUID) {
      //false parameter is here for disabling error handling
      api
        .getTicket(threadUUID, false)
        .then((resp) => {
          this.initData(resp.data);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            snackbar.error("Message thread not found we will redirect you");
          }
          api.getTicketList().then((resp) => {
            if (resp.data.count !== 0) {
              this.$router.push({
                name: "MessagesThread",
                params: { id: resp.data.results[0].uuid },
              });
            } else {
              this.$router.push({ name: "dashboards" });
            }
          });
        });
    },
  },
  watch: {
    $route(val) {
      this.loadMessageThread(val.params.id);
    },
    triggerUpdate(val) {
      this.note = {
        editedNote: { content: "", title: "", recipient: "" },
        members: [],
        active: false,
      };
    },
  },
};
</script>
<style scoped>
.assignee-gray {
  color: rgba(0, 0, 0, 0.6);
}
</style>
